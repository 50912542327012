import {Container} from '@webteam/layout';

function Header() {
    return (
        <header>
            <div className="response">
                <div className="wt-row wt-row_size_m wt-offset-top-24 wt-text-2">
                    <div className="wt-col-auto-fill">
                        <div className="goose"></div>
                    </div>
                    <div className="wt-col-inline wt-offset-top-24">
                        <a href="https://www.youtube.com/channel/UC0SQCpWKyvUyifuaPAhE8CQ"
                           className="text-link wt-link_theme_dark">YouTube</a>
                    </div>
                    <div className="wt-col-inline wt-offset-top-24">
                        <a href="https://www.instagram.com/cai_goose/"
                           className="text-link wt-link_theme_dark">Instagram</a>
                    </div>

                    <div className="wt-col-inline wt-offset-top-24">
                        <a href="https://twitter.com/cai_goose" className="text-link wt-link_theme_dark">Twitter</a>
                    </div>
                    <div className="wt-col-inline wt-offset-top-24">
                        <a href="https://github.com/computational-arts-initiative"
                           className="text-link wt-link_theme_dark">Git</a>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;
