import './App.css';
import './fonts/jetbrains-sans.css';

import '@webteam/layout';
import Card from "./Card";
import Project from "./Project";
import Header from "./Header";
import Footer from "./Footer";
import Button from "@webteam/button";

import tiler from "./img/tiler.jpg";
import nautilus from "./img/nautilus.jpg";
import eidos from "./img/eidos.jpg";
import gradient from "./img/gradient.png";
import limb from "./img/limb3.jpg";
import {useMemo} from "react";

function BannerImage({exploreUrl, articleUrl, projectName}) {
    return <div className={`big-banner ${projectName.replace(/ /g, '-').toLowerCase()}`}>
        <a className="banner-image" href={exploreUrl} target="_blank" ></a>
        <div className="banner-content">
            <h1 className="wt-hero">{projectName}</h1>
        </div>
        <div className="wt-row wt-row_size_s banner-content">
            <div className="wt-col-inline">
                <Button href={exploreUrl}
                        mode="contrast"
                        theme="dark">Explore</Button>
            </div>
            {articleUrl &&
                <div className="wt-col-inline">
                    <Button href={articleUrl}
                            mode="contrast"
                            theme="light">Read article</Button>
                </div>
            }
        </div>
    </div>;
}

const BANNERS = [
    {
        projectName: "Nautilus",
        exploreUrl: "https://code2art.jetbrains.com/",
    },
    {
        projectName: "Eidos",
        exploreUrl: "https://eidos-public.labs.jb.gg/",
    },
    {
        projectName: "Gradient",
        exploreUrl: "https://gradient.labs.jb.gg/",
    },

    {
        projectName: "Tiler",
        exploreUrl: "https://tiler.labs.jb.gg/",
    },
    {
        projectName: "Neobeam",
        exploreUrl: "https://neobeam.labs.jb.gg/",
    },
    {
        projectName: "Lorenz System",
        exploreUrl: "https://lorenz.labs.jb.gg/",
    },
    {
        projectName: "Origami",
        exploreUrl: "https://origami.labs.jb.gg/",
    },
    {
        projectName: "Waver",
        exploreUrl: "https://waver.labs.jb.gg/",
    },
    {
        projectName: "Jelly",
        exploreUrl: "https://jelly.labs.jb.gg/",
    },
    {
        projectName: "Seti",
        exploreUrl: "https://seti.labs.jb.gg/",
    },
    {
        projectName: "Dots",
        exploreUrl: "https://dots.labs.jb.gg/",
    },
    {
        projectName: "Randolor",
        exploreUrl: "https://randolor.labs.jb.gg/",
    },
    {
        projectName: "Plasmatic",
        exploreUrl: "https://plasmatic.labs.jb.gg/",
    }
];

function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

function App() {
    const index = useMemo(() => randomIntFromInterval(0, BANNERS.length - 1), []);

    return (
        <div className="wt-layout">
            <Header className="wt-layout__header"></Header>
            <main className="wt-layout__content">
                <div className="response">
                    <div className="wt-row wt-row_size_s wt-offset-top-24 wt-row_align-items_baseline">
                        <div className="wt-col-6 wt-col-md-6">
                            <h1 className="wt-subtitle-1">Computational<br/>Arts<br/>Initiative</h1>
                        </div>
                        <div className="wt-col-6 wt-col-md-12">
                            <p className="wt-text-1  wt-offset-top-md-24 wt-text-1_hardness_hard">
                                We&nbsp;explore art as&nbsp;the being, being raised generatively.
                                Yet&nbsp;to&nbsp;discover the truth of its origins in&nbsp;its full, yet
                                to&nbsp;find all the proofs needed, we&nbsp;perform experiments of&nbsp;any kind.
                                We&nbsp;search for similar laws between visual, audial and tangible. As&nbsp;we are
                                the initiative of&nbsp;researchers from different areas, developers, designers, as
                                well as&nbsp;specialists in&nbsp;neural networks, our topics to&nbsp;investigate
                                have close to&nbsp;no limits.</p>
                        </div>
                    </div>
                </div>
                <div className="response wt-offset-top-96">
                    <div className="wt-row">
                        <div className="wt-col-12">
                            <BannerImage {...BANNERS[index]}/>
                        </div>
                    </div>
                </div>
                <div className="response wt-offset-top-96">
                    <h1 className="wt-subtitle-1">Past releases</h1>
                    <div className="wt-row wt-row_size_s wt-offset-top-24">
                        <div className="wt-col-6 wt-col-sm-12 wt-offset-top-24 gray-card">
                            <Card title="Nautilus"
                                  imagePath={nautilus}
                                  projectLink="https://code2art.jetbrains.com/"
                                  articleLink="https://www.jetbrains.com/company/brand/desktop-art/"
                                  description="Immerse in the deep-sea vector generator's marvel, a splendid creation of cephalopod civilization where simplicity elegantly evolves into complexity."/>
                        </div>
                        <div className="wt-col-6 wt-col-sm-12 wt-offset-top-24 gray-card">
                            <Card title="Eidos"
                                  imagePath={eidos}
                                  projectLink="https://eidos-public.labs.jb.gg"
                                  articleLink="https://blog.jetbrains.com/blog/2023/10/16/ai-graphics-at-jetbrains-story/"
                                  description="AI-based generator which creates mesmerising surreal artworks inspired by JB products' palettes."/>
                        </div>
                    </div>
                </div>
                <div className="response wt-offset-top-96">
                    <div className="wt-row wt-row_size_s wt-row_align-items_baseline">
                        <div className="wt-col-6 wt-col-md-12">
                            <h1 className="wt-subtitle-1">Projects</h1>
                            <h1 className="wt-subtitle-1">and experiments</h1>
                        </div>
                        <div className="wt-col-6 wt-col-md-12">
                            <p className="wt-text-1 wt-offset-top-md-24">
                                Our projects are mostly generators intended to&nbsp;be&nbsp;used in&nbsp;marketing and
                                design, but we want our research to&nbsp;be&nbsp;accessible to&nbsp;everyone,
                                so&nbsp;we&nbsp;share both the tools and code with almost no&nbsp;restrictions.
                                Sometimes we&nbsp;use other projects as&nbsp;references, so&nbsp;without that research
                                our research would be&nbsp;impossible. Please be&nbsp;gentle with those products
                                of&nbsp;the mysterious computational art since sometimes they behave in&nbsp;<a
                                className="text-link" href="#contact-section">unexpected
                                ways</a></p>
                        </div>
                    </div>
                    <div className="wt-row wt-offset-top-48 wt-row_size_s">
                        {/*<Project title="Nautilus"*/}
                        {/*         imageName="nautilus"*/}
                        {/*         projectLink="https://code2art.jetbrains.com/"*/}
                        {/*         description="Immerse in the deep-sea vector generator's marvel, a splendid creation of cephalopod civilization where simplicity elegantly evolves into complexity."*/}
                        {/*/>*/}
                        {/*<Project title="Eidos"*/}
                        {/*         imageName="eidos"*/}
                        {/*         projectLink="https://eidos-public.labs.jb.gg"*/}
                        {/*         description="AI-based generator which creates mesmerising surreal artworks inspired by JB products' palettes."*/}
                        {/*/>*/}


                        <Project title="Gradient"
                                 imageName="gradient"
                                 projectLink="https://gradient-public.labs.jb.gg"
                                 description="We propose a rethinking of the gradient concept as a spatial expression of time, the fundamental basis of any dynamics, any movement and any drive."
                        />
                        <Project title="Tiler"
                                 imageName="tiler"
                                 projectLink="https://tiler.labs.jb.gg"
                                 description="We implemented a collaborative approach that combined individual artistic expression with a computational tiling algorithm."
                                 // articleLink="https://blog.jetbrains.com/blog/2021/06/16/art-of-tiling-and-mind-coalescence/"
                                 />

                        <Project title="Barista"
                                 imageName="barista"
                                 projectLink="https://barista.labs.jb.gg"
                                 description="This generator takes some shapes and composes a background with them, scattered across the screen." />
                        <Project title="Limb"
                                 imageName="limb"
                                 projectLink="https://limb.labs.jb.gg"
                                 description="Neural networks producing the beautiful artistic canvases in&nbsp;motion, with your help as&nbsp;the leader." />
                        <Project title="Metaballs"
                                 imageName="metaball"
                                 projectLink="https://metaballs.labs.jb.gg"
                                 description="The drops of&nbsp;thoughts being represented visually, escaping your urge to&nbsp;control them."/>
                        <Project title="Origami"
                                 imageName="origami"
                                 projectLink="https://origami.labs.jb.gg/"
                                 description="The paper folds in three dimensions below the wandering lights. Driven by WebGL and Flat Surface Shader."/>
                        <Project title="Waver"
                                 imageName="waver"
                                 projectLink="https://waver.labs.jb.gg/"
                                 description="Feathery ripples or a heavyweight tsunami? With this tool you can create colourful waves of any kind."/>
                        <Project title="Noodle"
                                 imageName="noodle"
                                 projectLink="https://noodle.labs.jb.gg/"
                                 description="Visual IDE for generative Animation and Sound, driven by data streams and functional programming."/>
                        <Project title="Kvant"
                                 imageName="kvant"
                                 projectLink="https://kvant.labs.jb.gg/"
                                 description="Wave Function Collapse as a library in Elm, for using with litreally anything + the editor for the rules."/>
                        <Project title="CoverBaker"
                                 imageName="coverbaker"
                                 projectLink="https://coverbaker.labs.jb.gg/#/"
                                 description="Do-It-Yourself Banner System for all JetBrains products and events"/>
                        <Project title="Randolor"
                                 imageName="randolor"
                                 projectLink="https://randolor.labs.jb.gg/"
                                 description="The tool for making a modular character with some random data, which also has some manual settings for a more customized look."/>
                        <Project title="Dots"
                                 imageName="dots"
                                 projectLink="https://dots.labs.jb.gg/"
                                 description="What about generating a grid-based dotted artwork? Play with colours and sizes, try different shapes or even experiment with emojis."/>
                        <Project title="Jelly"
                                 imageName="jelly"
                                 projectLink="https://jelly.labs.jb.gg/"
                                 description="It's always fun to recreate with code natural shapes and living beings. Let's try to generate a whimsical jelly fish shivering in the sea."/>
                        <Project title="Raygun"
                                 imageName="raygun"
                                 projectLink="https://raygun.labs.jb.gg/"
                                 description="Random JetBrains beam-logo generator"/>
                        <Project title="Plasmatic"
                                 imageName="plasmatic"
                                 projectLink="https://plasmatic.labs.jb.gg/"
                                 description="The shader-based generator that creates heavy plasma backgrounds"/>
                        <Project title="Neobeam"
                                 imageName="neobeam"
                                 projectLink="https://neobeam.labs.jb.gg/"
                                 description="Play with neon and heavy glow or draw your own constellation."/>
                        <Project title="Tron"
                                 imageName="tron"
                                 projectLink="https://tron.labs.jb.gg/"
                                 description="Universal stylish cross-platform User Interface to use with your generative art. Also detachable to any device."/>
                        <Project title="Seti"
                                 imageName="seti"
                                 projectLink="https://seti.labs.jb.gg/"
                                 description="The art-work generator based on Voronoi diagram with noodle interface"/>
                        <Project title="Lorenz system"
                                 imageName="lorenz"
                                 projectLink="https://lorenz.labs.jb.gg/ "
                                 description="The beautiful animated spiral galaxy based on Lorenz curve"/>
                    </div>
                </div>
                <hr className="wt-hr wt-hr_theme_dark wt-offset-top-96"/>
                <div className="response wt-offset-top-96" id="contact-section">
                    <div className="wt-row wt-row_size_s wt-row_align-items_baseline">
                        <div className="wt-col-6 wt-col-md-6">
                            <h1 className="wt-subtitle-1">Let’s talk</h1>
                        </div>
                        <div className="wt-col-6 wt-col-md-12">
                            <p className="wt-text-1 wt-text-1_hardness_hard wt-offset-top-md-24">
                                We&nbsp;always treat the unexpected as&nbsp;the grounding for a&nbsp;new
                                research, so&nbsp;please report to&nbsp;us if&nbsp;you face something of&nbsp;that kind.
                                Also, feel free to&nbsp;drop&nbsp;us a&nbsp;line or&nbsp;two, if&nbsp;you have any ideas
                                or&nbsp;just some warm words, as&nbsp;well as&nbsp;cold criticism.</p>
                            <p className="wt-text-1 wt-offset-top-24">
                                <a className="text-link" href="mailto:cai@jetbrains.com">cai@jetbrains.com</a>
                            </p>
                        </div>
                    </div>
                </div>
            </main>
            <Footer className="wt-layout__footer"></Footer>
        </div>
    );
}

export default App;
